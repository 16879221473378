
import { useNavigate } from 'react-router-dom'
import { Button } from '../componentes/Button'
import { Header } from '../componentes/Header'


export function Page404(){
    const history = useNavigate()
    const currentUrl = window.location.href
    
    return (
        <div id="page-room">
            <Header/>
            <main className='content'>
                <div className='room-404'>
                    <h1>Página não encontrada</h1>
                    <h3>{currentUrl}</h3>
                    <Button onClick={() => { history('/')}}>Voltar</Button>
                </div>
            </main>
        </div>
    )

}