import './componentes/Button'
import './styles/global.scss'
import 'react-toastify/dist/ReactToastify.css'

import { ToastContainer } from 'react-toastify'

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { RequireAuth } from './context/RequireAuth'
import { Page404 } from './pages/Page404'
import { PhotoDetail } from './pages/PhotoDetail'
import { Photo } from './pages/Photo'
import { PhotoNew } from './pages/PhotoNew'
import { Lotto } from './pages/Lotto'
import { NotLogin } from './pages/NotLogin'
import { Token } from './pages/Token'




function App() {

  return (
    <BrowserRouter>
      <div>
        <ToastContainer />
        <Routes>
          <Route path="*" element={<Page404 />} />
          <Route path="/login" element={<NotLogin />} />
          <Route path="/token/:id" element={<Token />} />
          
          <Route path="/" element={
            <RequireAuth>
              <Photo />
            </RequireAuth>
          }
          />

          <Route path="/photo/:id" element={
            <RequireAuth>
              <PhotoDetail />
            </RequireAuth>
          }
          />
          <Route path="/photo/new" element={
            <RequireAuth>
              <PhotoNew />
            </RequireAuth>
          }
          />
          <Route path="/photo" element={
            <RequireAuth>
              <Photo />
            </RequireAuth>
          }
          />
          <Route path="/lotto" element={
            <RequireAuth>
              <Lotto />
            </RequireAuth>
          }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
