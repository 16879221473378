
import '../styles/photo.scss'
import { useNavigate } from 'react-router-dom'
import { Header } from '../componentes/Header'
import { useEffect, useState } from "react"
import api from '../services/api'
import {PhotoType} from '../types/photo'
import { Button } from '../componentes/Button'
import { toast } from 'react-toastify'
import { ModalSubscription } from '../componentes/ModalSubscription'
import PaymentType from '../types/payment'



export function Photo() {
    const history = useNavigate()
    const [photos, setPhotos] = useState<PhotoType[]>([])
    const [loading, setLoading] = useState(true)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [payment, setPayment] = useState<PaymentType>()

    function closeModal() {
        setModalIsOpen(false)
    }

    async function openModal(id: string) {
        setModalIsOpen(true)
        await api.get('pix/' + id)
            .then((response) => {
                let updatedPayment = response.data
                setPayment(updatedPayment)
            })
            .catch((e) => {
                if (e.response) {
                    toast.error(e.response?.data.error)
                }
            })

    }


    useEffect(() => {
        async function loadVideos() {
            const response = await api.get('photo')
            setPhotos(response.data)
        }
        loadVideos()
        setLoading(false)
    }, []);

    return (
        <div id="page-room">
            <ModalSubscription isOpen={modalIsOpen} closeModal={closeModal} payment={payment} />
            <Header />
            <main className='content'>
                <div className='title-photo'>
                    <h1>Minhas Fotos</h1>
                    <Button onClick={() => { history('/photo/new') }}>Nova</Button>
                </div>
                <div className={`${loading ? 'container-photo' : 'hide'}`}>
                    <div className='searching'>
                        <h1>Carregando...</h1>
                    </div>
                </div>
                <div className={`${loading ? 'hide' : 'container-photo'}`}>
                    <div className="lista-photo">
                        {photos.length > 0 ? (
                            photos.map((photo) => {
                                return (
                                    <div key={photo.id} className='photo-table'>
                                        <div className='photo-item'>
                                            <div className='flex-col'>
                                                <label className='label'>Tipo</label>
                                                <label className='label-valor'>{photo.type}</label>
                                            </div>
                                            <div className='flex-col'>
                                                <label className='label'>Data Compra</label>
                                                <label className='label-valor'>{photo.date}</label>
                                            </div>
                                            <div className='flex-col'>
                                                <label className='label'>Código Pagamento</label>
                                                <label className='label-valor'>{photo.external_id}</label>
                                            </div>
                                            <div className='flex-col'>
                                                <label className='label'>Status</label>
                                                <label className='label-valor'>{photo.status}</label>
                                            </div>
                                            <div className='flex-col'>
                                                <label className='label'>Quantidade</label>
                                                <label className='label-valor'>{photo.quantity}</label>
                                            </div>
                                            <div className='flex-col'>
                                                <label className='label'>Valor</label>
                                                <label className='label-valor'>{parseFloat(photo.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</label>
                                            </div>
                                        </div>
                                        <div className='photo-botton'>
                                            {photo.status === "PAID" ? (
                                                <div className='flex-botton'>
                                                    <Button onClick={() => { history('/lotto' ) }}>Sorteio</Button>
                                                    <Button onClick={() => { history('/photo/' + photo.id) }}>Ver Fotos</Button>
                                                </div>
                                            ) : (
                                                <Button onClick={() => { openModal(photo.external_id) }}>Pagar</Button>
                                            )}
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <div className='no-data'>
                                <p>Você não comprou nenhuma foto ainda, em cada compra você ganha um número da sorte para concorrer a uma viagem* para a Mansão das Influenciadoras ou R$1.000,00 (um mil reais), sorteio realizado pela Loteria Federal. </p>
                                <Button onClick={() => { history('/photo/new') }}>Comprar foto e ganhar Número da Sorte</Button>
                            </div>
                        )}
                    </div>
                </div>
            </main>
        </div>
    )
}