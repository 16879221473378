
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'



export function Token(){
    const history = useNavigate()
    const param = useParams()

    useEffect(() => {
        const setToken = async () =>{
            console.log(param.id)
            localStorage.setItem("token",param?.id ? param.id : "")
            history("/")
        }
        setToken()
    }, [])
    
    return (
        <div id="page-room">
            <main className='content'>
                <div className='room-404'>
                    <h1>Atenção você não está logado</h1>
                    <h3>Volte para página de Login</h3>
                </div>
            </main>
        </div>
    )

}