import React, { useState } from 'react'
import { Button } from './Button'
import "../styles/pagination.scss"
import { PhotoItemType} from '../types/photo'

interface PaginationProps{
  items: PhotoItemType[]
  itemsPerPage: number
  handlePageChange: (newPage: number) => void
}

function Pagination({ items, itemsPerPage, handlePageChange }: PaginationProps) {
  const [currentPage, setCurrentPage] = useState(1)

  const totalPages = Math.ceil(items.length / itemsPerPage)

  const handlePageChangeSon = (newPage: number) => {
    setCurrentPage(newPage)
    handlePageChange(newPage)
  }


  return (
    <div className='pagination'>
      <div className='div-bt-pag'>
        <Button
          onClick={() => handlePageChangeSon(currentPage - 1)}
          className={currentPage === 1 ? "hide" : "button"}
        >
          {"<"}
        </Button>
      </div>
      <div className='div-h1-pag'>
        <h1>{items[currentPage-1]?.title}</h1>
      </div>
      <div className='div-bt-pag'>
        <Button
          onClick={() => handlePageChangeSon(currentPage + 1)}
          className={currentPage === totalPages ? "hide" : "button"}
        >
          {">"}
        </Button>
      </div>

    </div>
  )
}

export default Pagination