export function useIsAuth(){
    /*
    const [token, setToken] = useState('')
    const apiCandidatas = api.get('validaToken/token/'+localStorage.getItem("token"))
    .then((response) => setToken(response.data.token))
    return {token}
    */
   if(localStorage.getItem("token")){
       return true;
   }else{
       return false
   }

}