import "../styles/header.scss"
import { useNavigate, Link, useLocation } from 'react-router-dom'
import React, { useState } from 'react'
import logoImg from '../assets/img/logo.png'



export function Header() {
    const history = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    const location = useLocation()

    const toggleMenu = () => {
        setIsOpen(!isOpen)
    };

    function logout() {
        localStorage.clear()
        setIsOpen(!isOpen)
        window.location.href = "https://play.ppkbrasil.com.br/"
    }

    function home() {
        history('/')
    }

    function closeMenu(){
        setIsOpen(!isOpen)
    }


    return (
        <header>
            <div className="content-header">
                <nav className={`navbar ${isOpen ? 'active' : ''}`}>
                    <img className="logo" onClick={home} src={logoImg} alt="Logo" />
                    <ul className={`menu ${isOpen ? 'active' : ''}`}>
                        <li><Link to="/" onClick={closeMenu} className={location.pathname === '/' ? 'item-active' : ''}>Home</Link></li>
                        <li><Link to="/lotto" onClick={closeMenu} className={location.pathname === '/lotto' ? 'item-active' : ''}>Meus Números</Link></li>
                        <li><span onClick={logout}>Sair</span></li>
                    </ul>
                    <div className={`menu-toggle ${isOpen ? 'active' : ''}`} onClick={toggleMenu}>
                        <svg viewBox="0 0 100 80" width="40" height="40">
                            <rect width="100" height="20" rx="10"></rect>
                            <rect y="30" width="100" height="20" rx="10"></rect>
                            <rect y="60" width="100" height="20" rx="10"></rect>
                        </svg>
                    </div>
                </nav>
            </div>
        </header>

    )
}