import '../styles/lotto.scss'
import { useEffect, useState } from "react"
import { Header } from "../componentes/Header"
import api from '../services/api'
import { Button } from '../componentes/Button'
import { toast } from 'react-toastify'
import { ModalTop } from '../componentes/ModalTop'
import { useNavigate } from 'react-router-dom'

type NumberType = {
    id: string
    date: string
    external_id: string
    number: string
}



export function Lotto() {
    const history = useNavigate()
    const [loading, setLoading] = useState(true)
    const [numbers, setNumbers] = useState<NumberType[]>([])
    const [modalIsOpen, setModalIsOpen] = useState(false)

    function closeModal() {
        setModalIsOpen(false)
    }

    async function openModal() {
        setModalIsOpen(true)
    }

    useEffect(() => {
        async function loadNumbers() {
            const response = await api.get('numbers')
            setNumbers(response.data)
        }
        loadNumbers()
        setLoading(false)
    }, []);

    return (
        <div id="page-room">
            <ModalTop closeModal={closeModal}  isOpen={modalIsOpen} />
            <Header />
            <main className='content-lotto'>
                <div className='title-lotto'>
                    <h1>Meus Números</h1>
                    <Button onClick={() => { openModal() }}>Top 10</Button>
                </div>
                <div className={`${loading ? 'container-lotto' : 'hide'}`}>
                    <div className='searching'>
                        <h2>Carregando...</h2>
                    </div>
                </div>
                <div className={`${loading ? 'hide' : 'container-lotto'}`}>
                    <div className="lista-lotto">
                        {numbers.length > 0 ? (
                            numbers.map((number) => {
                                return (
                                    <div key={number.id} className='lotto-table'>
                                        <div className='lotto-item'>
                                            <div className='flex-col'>
                                                <label className='label'>Número</label>
                                                <label className='label-valor-destaque'>{number.number}</label>
                                            </div>
                                            <div className='flex-col'>
                                                <label className='label'>Data</label>
                                                <label className='label-valor'>{number.date}</label>
                                            </div>
                                            <div className='flex-col'>
                                                <label className='label'>Pagamento</label>
                                                <label className='label-valor'>{number.external_id}</label>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            )
                        ) : (
                            <div className='no-data'>
                                <p>Você não comprou nenhuma foto ainda, em cada compra você ganha um número da sorte para concorrer a uma viagem* para a Mansão das Influenciadoras ou R$1.000,00 (um mil reais), sorteio realizado pela Loteria Federal. </p>
                                <Button onClick={() => { history('/photo/new') }}>Comprar foto e ganhar Número da Sorte</Button>
                            </div>
                        )}

                    </div>

                </div>
            </main>
        </div>
    )
}