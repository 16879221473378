import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import api from "../services/api"
import UserType from "../types/user"
import { toast } from "react-toastify"

export function useUserAuth() {
  const history = useNavigate()
  const [user, setUser] = useState<UserType | null>(null)

  useEffect(() => {
    const checkUserAuth = async () => {
      try {
        const response = await api.get("me")
        if (response.data.status === "OK") {
          setUser(response.data.dados)
        } else {
          handleAuthenticationFailure()
        }
      } catch (error) {
        handleAuthenticationFailure()
      }
    }

    const handleAuthenticationFailure = () => {
      localStorage.clear()
      toast.error("Authentication failed. Please log in again.")
      history("/login")
    }

    checkUserAuth()
  }, [history])

  return { user }
}
