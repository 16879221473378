import React, { useState, useEffect } from 'react'
import api from '../services/api'

interface Option {
    id: string
    label: string
    value: string
    quantity: string
}

interface SelectCategoryProps {
    onSelectChange?: (price_id: string) => void,
    product_id:string
}

function SelectPrice(props: SelectCategoryProps) {
    const [data, setData] = useState<Option[]>([]) // Estado para armazenar os dados da API
    const [priceselect, setPriceselect] = useState<Option>({
        id: "",
        label: "",
        quantity: "",
        value: ""
    }) // Estado para armazenar a seleção do usuário

    function setHandleSelectChange(value: string) {
        const updated = { ...priceselect }
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === value) {
                updated.quantity = data[i].quantity
                updated.value = data[i].value
                updated.id = data[i].id
                updated.label = data[i].label
            }
        }
        setPriceselect(updated)
        if (props.onSelectChange) {
            props.onSelectChange(value)
        }
    }

    useEffect(() => {
        // Função para buscar os dados da API
        async function fetchData() {
            try {
                await api.post('prices', new URLSearchParams({
                    prices: props.product_id
                }))
                    .then((response) => {
                        setData(response.data)
                        setPriceselect(response.data[0])
                        if (props.onSelectChange) {
                            props.onSelectChange(response.data[0].id)
                        }
                    })
            } catch (error) {
                console.error('Erro ao buscar os dados da API:', error)
            }
        }

        fetchData(); // Chama a função de busca quando o componente é montado
    }, [])

    return (
        <div>
            <select name='category' value={priceselect.id} onChange={event => setHandleSelectChange(event.target.value)}>
                {data.map(item => (
                    <option key={item.id} value={item.id}>
                        {item.label}
                    </option>
                ))}
            </select>
            <input
                readOnly
                type='text'
                placeholder='Valor'
                value={parseFloat(priceselect?.value ? priceselect.value : '0.01').toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            />
        </div>
    );
}

export default SelectPrice
