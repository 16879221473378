import { useEffect, useState } from "react"
import { PhotoType, PhotoItemType } from "../types/photo"
import { toast } from "react-toastify"
import { Header } from "../componentes/Header"
import { useNavigate, useParams } from "react-router-dom"
import api from "../services/api"
import '../styles/photo-detail.scss'
import Pagination from "../componentes/Pagination"

export function PhotoDetail() {
    const history = useNavigate()
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [item, setItem] = useState<PhotoItemType[]>([])
    const [currentPage, setCurrentPage] = useState(1)


    function handleImageLoad() {
        setTimeout(() => {
            setLoading(false)
        }, 500)
    }

    function handlePageChange(newPage: number) {
        setLoading(true)
        setCurrentPage(newPage)
        setTimeout(() => {
            setLoading(false)
        }, 1500)

    }

    useEffect(() => {

        async function loadphotos() {
            const response = await api.get(`photo/${id}`)
            if (response.data.msg) {
                toast.error(response.data.msg)
                history("/")
                return
            }
            if(response.data.item.length>0){
                setItem(response.data.item)
            }else{
                history("/photo")
            }
        }
        loadphotos()
    }, [history, id]);

    return (
        <div id="page-room">
            <Header />
            <main className='content'>
                <div className='photo-info'>
                    <Pagination items={item} itemsPerPage={1} handlePageChange={handlePageChange} />
                </div>
                <div className={`${loading ? 'photo-info' : 'hide'}`}>
                    <div className="photo-title">
                        <h1>Carregando sua foto...</h1>
                    </div>
                </div>
                <div className={`${loading ? 'hide' : 'photo-info'}`}>
                    <img onLoad={handleImageLoad} className="photo" src={item[currentPage - 1]?.url} alt="Foto Ana Otani" />
                    <img className="msg" src={item[currentPage - 1]?.msg} alt="Foto Ana Otani" />
                </div>
            </main>
        </div>
    )
}