import ReactModal from 'react-modal'
import Modal from 'react-modal'
import { Button } from './Button'
import '../styles/modal-subscription.scss'
import '../styles/subscription-new.scss'
import { useEffect, useState } from 'react'
import { useUserAuth } from '../hooks/useUserAuth'
import PaymentType from '../types/payment'
import { FormPix } from './subscription/FormPix'
import api from '../services/api'
import { toast } from 'react-toastify'

Modal.setAppElement('#root')

interface ModalSubscriptionProps {
    isOpen: boolean
    closeModal: () => void
    payment: PaymentType | undefined
}

export function ModalSubscription(props: ModalSubscriptionProps) {
    const { user } = useUserAuth()
    const [carregado, setCarregando] = useState(false)

    async function handleMain(newPayment: PaymentType) {
        alert('Olá')

    }

    async function carregar(status:boolean) {
        setCarregando(status)
    }

    useEffect(() => {
        async function load() {
            setCarregando(true)
        }
        load()
    }, [props.payment])

    
    return (
        <ReactModal
            isOpen={props.isOpen}
            contentLabel="Detalhes da Assinatura"
            className='modal'
            overlayClassName='overlay-modal'
            onAfterOpen={() => {carregar(false)}}
            onAfterClose={() => {carregar(false)}}
        >
            <div className='top-close'>
                <Button onClick={props.closeModal}>X</Button>
            </div>
            <div className='modal-detail'>
                {user && props.payment && carregado &&(
                    <FormPix onChange={handleMain} payment={props.payment} />
                )}
                {!carregado && (
                    <h3>Carregando...</h3>
                )}
            </div>
        </ReactModal>
    )
}