
import { useNavigate } from 'react-router-dom'
import { Button } from '../componentes/Button'
import { Header } from '../componentes/Header'


export function NotLogin(){
    const history = useNavigate()
    async function handleLogin(){
        localStorage.setItem("token","d6f9574c8e5af3e4c686dad0f4b2a72a320498467ff30213b4604eb4328a0dce2c14a5812f97ee35d7fcea2fbd87ad427cf5a52d796842339fd171fd3715ca90")
        history("/")
    }
    
    return (
        <div id="page-room">
            <main className='content'>
                <div className='room-404'>
                    <h1>Atenção você não está logado</h1>
                    <h3>Volte para página de Login {process.env.REACT_APP_PUBLIC_URL}</h3>
                    <Button onClick={handleLogin}>Fazer Login</Button>
                </div>
            </main>
        </div>
    )

}