import { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import Modal from 'react-modal'
import api from '../services/api'
import { toast } from 'react-toastify'
import { Button } from './Button'


Modal.setAppElement('#root')

type TopType = {
    name: string
    quantity: string
}

interface ModalTopProps {
    isOpen: boolean
    closeModal: () => void
}

export function ModalTop(props: ModalTopProps) {
    const [carregado, setCarregando] = useState(false)
    const [top, setTop] = useState<TopType[]>([])
    let ranking = 0

    useEffect(() => {
        async function loadTop() {
            await api.get('top')
                .then((response) => {
                    let updatedPayment = response.data
                    setTop(updatedPayment)
                    setCarregando(true)
                })
                .catch((e) => {
                    if (e.response) {
                        toast.error(e.response?.data.error)
                    }
                })
        }
        loadTop()
    }, []);



    return (
        <ReactModal
            isOpen={props.isOpen}
            contentLabel="Detalhes da Assinatura"
            className='modal'
            overlayClassName='overlay-modal'
        >
            <div className='top-close'>
                <Button onClick={props.closeModal}>X</Button>
            </div>
            <div className='modal-lotto'>

                {!carregado && (
                    <h3>Carregando...</h3>
                )}
                {carregado && (
                    <div className='lotto-table-top'>
                        <div className='lotto-item'>
                            <div className='flex-col'>
                                <label className='label'>Pos</label>
                            </div>
                            <div className='flex-col'>
                                <label className='label'>Nome</label>
                            </div>
                            <div className='flex-col'>
                                <label className='label'>Quant</label>
                            </div>
                        </div>
                    </div>
                )}
                {carregado && (
                    top.map((number) => {
                        ranking = ranking+1
                        return (
                            <div key={ranking} className='lotto-table-top'>
                                <div className='lotto-item'>
                                    <div className='flex-col'>
                                        <label className='label-valor'>{ranking}</label>
                                    </div>
                                    <div className='flex-col'>
                                        <label className='label-valor'>{number.name}</label>
                                    </div>
                                    <div className='flex-col'>
                                        <label className='label-valor'>{number.quantity}</label>
                                    </div>
                                </div>
                            </div>
                        )
                        
                    })
                )}
            </div>

        </ReactModal>
    )
}