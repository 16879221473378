import '../styles/photo-new.scss'
import { Header } from "../componentes/Header"
import { FormEvent, useState } from 'react'
import { toast } from 'react-toastify'
import { useUserAuth } from '../hooks/useUserAuth'
import { Button } from '../componentes/Button'
import api from '../services/api'
import { useNavigate } from 'react-router-dom'
import SelectPrice from '../componentes/SelectPrice'




export function PhotoNew() {
    const { user } = useUserAuth()
    const history = useNavigate()

    const [termsAccepted, setTermsAccepted] = useState(false)
    const [ruleAccepted, setRuleAccepted] = useState(false)
    const [option, setOption] = useState('')

    async function submit(event: FormEvent) {
        event.preventDefault()
        if (termsAccepted && ruleAccepted) {
            await api.post('photo/pix', new URLSearchParams({
                id: option
            }))
                .then((response) => {
                    toast.info(response?.data.msg)
                    history('/photo')
                })
                .catch((e) => {
                    if (e.response) {
                        toast.error(e.response?.data.error)
                    }
                })
        } else {
            toast.error('Você precisa aceitar os Termos de Uso, as Políticas de Privacidade e o Regulamento do Sorteio.')
        }
    }

    async function atualizaValor(value: string) {
        setOption(value)
    }

    async function handleTermsAcceptance() {
        setTermsAccepted(!termsAccepted)
    }

    async function handleRuleAccepted() {
        setRuleAccepted(!ruleAccepted)
    }

    

    return (
        <div id="page-room">
            <Header />
            <main className='content-photo-new'>
                <div className='title'>
                    <h1>Nova Foto</h1>
                </div>
                <div className='description'>
                    <label>A cada foto comprada ganhe um número da sorte para concorrer a uma viagem* para a Mansão das Influenciadoras, sorteio realizado pela Loteria Federal.</label>
                </div>
                <div className='form'>
                    <form onSubmit={submit}>
                        <input
                            readOnly
                            type='text'
                            placeholder='Nome'
                            value={user?.name || ''}
                        />
                        <SelectPrice product_id='1' onSelectChange={atualizaValor}/>
                        <div>
                            <input
                                className='checkbox'
                                type="checkbox"
                                checked={termsAccepted}
                                onChange={handleTermsAcceptance}
                            />
                            <label>Declaro que eu aceito os Termos de Uso e as Políticas de Privacidade</label>
                        </div>
                        <div>
                            <input
                                className='checkbox'
                                type="checkbox"
                                checked={ruleAccepted}
                                onChange={handleRuleAccepted}
                            />
                            <label>Declaro que eu aceito o Regulamento do Sorteio</label>
                        </div>
                        <Button>Avançar</Button>
                    </form>
                </div>
            </main>
        </div>
    )
}